@import "../../assets/variables/color.scss";

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;

  @media screen and (min-width: 575px) {
    border-radius: 46px 46px 0 0;
  }
}

.before::before {
  display: none;
}

.posterContainer {
  position: fixed;
  width: 100%;
  height: 50vh;

  @media screen and (min-width: 575px){
    width: 395px;
    height: 400px;
    border-radius: 46px 46px 0 0;
  }

  @media screen and (min-width: 575px) and (min-height: 1080px) {
      height: 485px;
      width: 435px;
  }
}

.resultPosterContainer {
  min-height: 55vh;
  background-color: $white;

  @media screen and (min-width: 575px) {
    min-height: auto;
  }
}

.resultPosterContainer::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s;

  @media screen and (min-width: 575px) {
    display: none;
  }
}

.onScrollOption::before {
  background-color: rgba(0, 0, 0, 0.6);
  top: 8px;
  transition: all 0.2s;
}

.social {
  margin-top: 8px;
  display: flex;
  gap: 0 8px;
}

.share {
  height: 40px;
  width: 40px;
  background-color: #f0f0f0 !important;
  border-radius: 6px !important;

  &:hover {
    background-color: #f0f0f0 !important;
    opacity: 0.8;
  }
}

.icon {
  height: 40px;
  width: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & > circle {
    display: none;
  }

  & > path {
    display: none;
  }

  &:hover {
    opacity: 0.8;
  }
}

.facebook {
  background-image: url(../../../src/assets/images/social/social-facebook.svg);
}

.viber {
  background-image: url(../../../src/assets/images/social/social-viber.svg);
}

.whatsapp {
  background-image: url(../../../src/assets/images/social/social-whatsapp.svg);
}

.telegram {
  background-image: url(../../../src/assets/images/social/social-telegram.svg);
}

.instagram {
  background-image: url(../../../src/assets/images/social/social-instagram.svg);
}


.buttonBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0;

  button {
    margin: 0 16px;
  }
}

.outline {
  background-color: $white;
  border: 1px solid $accent;

  &:hover {
    background-color: $accent;
  }
}

.score {
  font-size: 32px;
  font-weight: 600;
}

.contentWrapper {
  margin: 0 16px;
}

.socialWrapper {
  padding-bottom: 24px;
}

.inputPromoWrapper {
  width: 100%;
  display: flex;
  position: relative;

  @media screen and (min-width: 992px) {
    width: auto;
  }
}

.form {
  width: 100%;

  @media screen and (min-width: 992px) {
    width: auto;
  }
}

.buttonBoxLink {
  text-align: center;
  text-decoration: underline;
  color: $text;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    transition: all 0.2s;
  }
}

.promoBox {
  // margin-top: 24px;
  background-color: $white-bg;
  padding: 3.5%;
  border-radius: 32px;
  margin: 24px 16px;
/*
  @media (min-width: 992px) and (max-width: 1370px) {
    background-color: $white;
    padding: 0;
    margin-top: 0;
  }
*/
  @media screen and (min-width: 1370px) {
    margin-top: 4%;
  }
}

.inputPromo {
  border: 0;
  background-color: $white;
  padding: 14px 24px;
  border-radius: 32px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  outline: none;

  // @media (min-width: 992px) and (max-width: 1370px) {
  //   background-color: $white-bg;
  //   width: auto;
  //   font-size: 20px;
  //   line-height: 32px;
  //   padding: 10px 24px;
  // }

  // @media screen and (min-width: 1370px) {
  //   width: auto;
  //   font-size: 20px;
  //   line-height: 32px;
  // }
}

.copy {
  position: absolute;
  right: 8px;
  top: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 32px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $white;

  &:hover {
    background-color: $white-bg;
    transition: all 0.2s;
  }

  // @media (min-width: 1370px) {
  //   top: 10px;
  // }
}

.copySuccess {
  background-color: $accent-diasable;
  position: absolute;
  left: 24px;
  top: 2vh;
  padding: 16px 32px;
  border-radius: 8px;
  transition: all 0.2s;
  display: none;

  // @media screen and (min-width: 992px) {
  //   display: block;
  //   bottom: 2%;
  //   left: 5.5%;
  //   top: auto;
  // }
}

.resultContainer {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  max-width: 575px;
  height: 55vh;
  border-radius: 35px 35px 0 0;
  padding: 24px 0;

     @media screen and (min-width: 575px) {
   /*   width: 395px;*/
      height: 470px;
    }
}

.resultContainerBox {
  width: 100%;
  padding: 24px 16px;
  border-radius: 24px;
  background-color: $white;
  overflow-y: scroll;
  
  ul {
   padding-left: 20px;
  }

  @media screen and (min-width: 575px) {
    width: 100%;
    height: 504px;
    border-radius: 24px;
  }
}

.resultContainerBox::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s;
}